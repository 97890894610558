/*
 * @Author: mxh
 * @Date: 2021-11-16 15:54:50
 * @LastEditTime: 2022-01-19 17:59:13
 * @LastEditors: mxh
 * @Description: 用户管理相关api
 * @status:
 */

import {postAction, getAction, putAction} from '@/api/require';

/* ###################################################################################### */
// @description 获取用户列表数据
// #region
/**
 * @description: 获取用户列表数据
 * @param {*} payload get请求携带参数
 * @return {*}
 */
export const api_getUserList = payload => getAction('/admin/user/list', payload);
// #endregion
/* ###################################################################################### */

/* ###################################################################################### */
// @description 对用户进行操作
// #region
/**
 * @description: 封禁用户
 * @param {*} payload
 * @return {*}
 */
interface banUser {
    userIds: Array<number>;
    reason: string;
    endTime?: string | null;
    type: number | string;
}
export const api_banUser = (payload: banUser) => postAction('/admin/user/ban', payload);

/**
 * @description: 解封用户
 * @param {*}
 * @return {*}
 */
interface unlockUser {
    userIds: Array<number>;
}
export const api_unlockUser = (payload: unlockUser) => putAction('/admin/user/unlock', payload);

/**
 * @description: 变更用户角色
 * @param {*}
 * @return {*}
 */
interface changeUserRole {
    userId: number;
    roleId: number;
}
export const api_changeUserRole = (payload: changeUserRole) => putAction('/admin/user/role/modify', payload);

interface notifyUser {
    userIds: Array<number>;
    content: string;
}
export const api_notifyUser = (payload: notifyUser) => postAction('/admin/user/notify', payload);

/**
 * @description: 清除用户信息
 * @param {*}
 * @return {*}
 */
interface clearUserInfo {
    userId: number;
    target: number;
}
export const api_clearUserInfo = (payload: clearUserInfo) => putAction('/admin/user/clear', payload);

/**
 * @description: 警告用户
 * @param {*}
 * @return {*}
 */
interface warringUser {
    userId: number;
    content: string;
}

export const api_warringUser = (payload: warringUser) => postAction('/admin/user/caution', payload);

interface classifyTag {
    tagId: number;
    sectionId: number;
}
/**
 * @description: 标签分类操作
 * @param {classifyTag} payload
 * @return {*}
 */
export const api_classifyTag = (payload: classifyTag) => postAction('/admin/tag/classify', payload);

interface getReportList {
    index: number;
    size: number;
}
/**
 * @description: 获取用户举报列表
 * @param {*}
 * @return {*}
 */
export const api_getReportList = pageInfo => getAction('/admin/statistics/report/list', pageInfo);

interface setReportStatus {
    reportId: number;
}
/**
 * @description: 修改举报状态
 * @param {*}
 * @return {*}
 */
export const api_setReportStatus = (payload: setReportStatus) => putAction('/admin/statistics/report/status/set', payload);

/**
 * @description: 获取用户信息
 * @param {*} payload
 * @return {*}
 */
export const api_getUserInfo = payload => getAction('/profile/info', payload);

interface createAccount {
    aidAccount: String;
    password: String;
}
/**
 * @description: 创建内部账户
 * @param {*} payload
 * @return {*}
 */
export const api_createAccount = (payload: createAccount) => postAction('/admin/user/account/register', payload);

interface getAccount {
    index: number;
    size: number;
}
/**
 * @description: 获取内部账户列表
 * @param {*} payload
 * @return {*}
 */
export const api_getAccount = (payload: getAccount) => getAction('/admin/user/account/list', payload);

/**
 * @description: 增加用户发布模型空间
 * @param {*} payload
 * @return {*}
 */
export const api_addUserModelSpace = payload => postAction('/admin/user/space/increase', payload);
// #endregion
/* ###################################################################################### */
