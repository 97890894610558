/*
 * @Author: mxh
 * @Date: 2021-11-18 10:35:50
 * @LastEditTime: 2022-01-24 17:48:49
 * @LastEditors: mxh
 * @Description: 全局公共静态方法
 * @status:
 */
import React from 'react';
import moment from 'moment';
import store from '@/store';
import {message} from 'antd';
import {Md5} from 'ts-md5/dist/md5';
import debounce from 'lodash/debounce';
import DartData from '@/tools/dark';

store.subscribe(() => {
    // 全局夜间模式对象
    React.$dartData = DartData(store.getState().theme);
});
// 全局loading控制
Object.defineProperty(React, 'loading', {
    get: () => payload => {
        store.dispatch({
            type: 'changeLoding',
            payload
        });
    }
});

// 全局图片地址
React['getImgUrl'] = name => {
    if (name.includes('http')) {
        return name;
    }
    return store.getState().downUrl + name;
};

// 时间转换
Object.defineProperty(React, 'formatTime', {
    get:
        () =>
        (time, layout = 'YYYY-MM-DD HH:mm:ss') => {
            if (time) {
                return moment(time).format(layout);
            } else {
                return null;
            }
        }
});

// 返回moment格式的时间
React.$momentTime = time => {
    return moment(time);
};

// 全局提示
message.config({
    duration: 3,
    getContainer: () => document.getElementById('page-right') || document.querySelector('body'),
    top: 80,
    maxCount: 3
});
React.$message = message;

// MD5 加密
React['getMD5'] = str => {
    const md5 = new Md5();
    md5.appendStr(str);
    return md5.end();
};

// 检查内部账户用户名
React.$username = str => {
    // return /^aidlux\.\d{3}$/.test(str);
    return true;
};
// 检查内部账户密码
React.$checkPassword = str => {
    return /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{6,16}$/.test(str);
};

// 全局静态防抖函数
React.$debounce = debounce;
