/*
 * @Author: mxh
 * @Date: 2021-11-30 15:53:58
 * @LastEditTime: 2022-01-24 18:34:52
 * @LastEditors: mxh
 * @Description: 全局路由配置
 * @status:
 */
import React, {lazy, Suspense} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {Decoration9} from '@jiaminghi/data-view-react';
// 组件菜单图标
import {
    ContainerOutlined,
    PieChartOutlined,
    RadarChartOutlined,
    AccountBookOutlined,
    ProfileOutlined,
    CrownOutlined,
    BugOutlined,
    CarOutlined,
    FireOutlined,
    HeatMapOutlined,
    AimOutlined,
    FileTextOutlined,
    ApiOutlined,
    DashboardOutlined,
    WifiOutlined,
    KeyOutlined,
    AppstoreOutlined,
    AppstoreAddOutlined,
    TagOutlined,
    TagsOutlined,
    WarningOutlined,
    RobotOutlined,
    TeamOutlined,
    BarChartOutlined,
    SendOutlined,
    StockOutlined,
    UserAddOutlined,
    RedditOutlined,
    ContactsOutlined,
    InsertRowRightOutlined
} from '@ant-design/icons';

// 路由组件
const Monitor = lazy(() => import('@/pages/monitor'));
const Protocol = lazy(() => import('@/pages/protocol'));
const Tag = lazy(() => import('@/pages/block/tag'));
const Classify = lazy(() => import('@/pages/block/classify'));
const ClassifyTag = lazy(() => import('@/pages/block/classifytag'));
const AdvItem = lazy(() => import('@/pages/advertising/index'));
const Side = lazy(() => import('@/pages/advertising/side'));
const ActiveList = lazy(() => import('@/pages/activity/list'));
const ActiveSend = lazy(() => import('@/pages/activity/send'));
const TopSta = lazy(() => import('@/pages/statistics/topSta'));
const UserSta = lazy(() => import('@pages/statistics/dataSta/user'));
const StaContent = lazy(() => import('@pages/statistics/dataSta/content'));
const ClassifyAnalysis = lazy(() => import('@/pages/analysis/classify/classify'));
const EntiretyAnalysis = lazy(() => import('@/pages/analysis/entirety/entirety'));
const TagAnalysis = lazy(() => import('@/pages/analysis/tag/tag'));
const PostManage = lazy(() => import('@pages/content/postManage'));
const Power = lazy(() => import('@/pages/user/role'));
const UserList = lazy(() => import('@pages/user/userList'));
const RoleRoute = lazy(() => import('@/pages/user/authority'));
const ReviewManage = lazy(() => import('@/pages/content/reviewManage'));
const ReportInfo = lazy(() => import('@pages/content/reportInfo'));
const RecommendMan = lazy(() => import('@pages/content/recommend'));
const DynamicManage = lazy(() => import('@pages/content/dynamic'));
const Post = lazy(() => import('@pages/content/post'));
const NewAccount = lazy(() => import('@pages/user/newAccount'));
const IsDeveloper = lazy(() => import('@pages/developer/is-developer'));
const DeveloperCase = lazy(() => import('@pages/developer/case'));

export const routes: any = [
    {
        key: '/monitoring',
        path: '/monitoring',
        title: '整体概况',
        icon: BarChartOutlined,
        component: () => <Monitor></Monitor>
    },
    {
        key: '/user',
        path: '/user',
        title: '用户管理',
        icon: TeamOutlined,
        component: () => <UserList></UserList>
    },
    {
        key: 'authority-manager',
        path: '/authority',
        title: '权限管理',
        icon: KeyOutlined,
        children: [
            {
                key: 'role-manager',
                path: 'roles',
                title: '角色管理',
                icon: RobotOutlined,
                component: () => <Power></Power>
            },
            {
                key: 'routes-manager',
                path: 'routes',
                title: '路由管理',
                icon: WifiOutlined,
                component: () => <RoleRoute></RoleRoute>
            },
            {
                key: 'internal-account',
                path: 'new-account',
                title: '新增内部账户',
                icon: UserAddOutlined,
                component: () => <NewAccount></NewAccount>
            }
        ]
    },
    {
        key: 'sub2',
        path: '/block',
        title: '板块管理',
        icon: AppstoreAddOutlined,
        children: [
            {
                key: '2',
                path: 'classify',
                title: '板块列表',
                icon: AppstoreOutlined,
                component: () => <Classify></Classify>
            },
            {
                key: '3',
                path: 'tag',
                title: '标签管理',
                icon: TagOutlined,
                component: () => <Tag></Tag>
            },
            {
                key: '4',
                path: 'classifytag',
                title: '标签分类',
                icon: TagsOutlined,
                component: () => <ClassifyTag></ClassifyTag>
            }
        ]
    },
    {
        key: '6',
        path: '/content',
        title: '内容管理',
        icon: ContainerOutlined,
        children: [
            {
                key: 'content-3',
                path: 'post-manage',
                title: '帖子管理',
                icon: FileTextOutlined,
                component: () => <PostManage></PostManage>
            },
            {
                key: 'content-6',
                path: 'report-info',
                title: '举报信息',
                icon: WarningOutlined,
                component: () => <ReportInfo></ReportInfo>
            },
            {
                key: 'post-send',
                path: 'post-send',
                title: '帖子发布',
                icon: SendOutlined,
                component: () => <Post></Post>
            },
            {
                key: '',
                path: 'dynamic',
                title: '动态管理',
                icon: StockOutlined,
                component: () => <DynamicManage></DynamicManage>
            },
            {
                key: 'content-4',
                path: 'review-manage',
                title: '评论管理',
                icon: ApiOutlined,
                component: () => <ReviewManage></ReviewManage>
            },
            {
                key: 'content-5',
                path: 'recommend-man',
                title: '推荐逻辑',
                icon: ApiOutlined,
                component: () => <RecommendMan></RecommendMan>
            }
        ]
    },
    {
        key: '/protocol',
        path: '/protocol',
        title: '用户协议',
        icon: ProfileOutlined,
        component: () => <Protocol></Protocol>
    },
    {
        key: '/adv',
        path: '/adv',
        title: '广告管理',
        icon: CrownOutlined,
        children: [
            {
                key: '10',
                path: 'adv',
                title: '广告',
                icon: HeatMapOutlined,
                component: () => <AdvItem></AdvItem>
            },
            {
                key: '11',
                path: 'side',
                title: '边栏排序',
                icon: AimOutlined,
                component: () => <Side></Side>
            }
        ]
    },
    {
        key: 'statistics',
        path: '/statistics',
        title: '运营统计',
        icon: RadarChartOutlined,
        children: [
            {
                key: 'statistics-1',
                title: '内容统计',
                path: 'contentSta',
                icon: AppstoreAddOutlined,
                component: () => <StaContent></StaContent>
            },
            {
                key: 'statistics-2',
                title: '用户统计',
                path: 'userSta',
                icon: AppstoreAddOutlined,
                component: () => <UserSta></UserSta>
            },
            {
                key: 'statistics-3',
                title: '排行榜单',
                path: 'topSta',
                icon: AppstoreAddOutlined,
                component: () => <TopSta></TopSta>
            }
        ]
    },
    {
        key: 'analysis',
        path: '/analysis',
        title: '数据分析',
        icon: PieChartOutlined,
        children: [
            {
                key: 'analysis-1',
                path: 'entirety',
                title: '整体概览',
                icon: BugOutlined,
                component: () => <EntiretyAnalysis></EntiretyAnalysis>
            },
            {
                key: 'analysis-2',
                path: 'classify-analyse',
                title: '分类分析',
                icon: CarOutlined,
                component: () => <ClassifyAnalysis></ClassifyAnalysis>
            },
            {
                key: 'analysis-4',
                path: 'tag-analyse',
                title: '标签分析',
                icon: FireOutlined,
                component: () => <TagAnalysis></TagAnalysis>
            }
        ]
    },
    {
        key: 'active',
        path: '/activity',
        title: '活动管理',
        icon: AccountBookOutlined,
        children: [
            {
                key: 'active-1',
                path: 'list',
                title: '活动列表',
                icon: AppstoreAddOutlined,
                component: () => <ActiveList></ActiveList>
            },
            {
                key: 'active-2',
                path: 'send',
                title: '活动发布',
                icon: DashboardOutlined,
                component: () => <ActiveSend></ActiveSend>
            }
        ]
    },
    {
        key: 'developer',
        path: '/developer',
        title: '认证开发者',
        icon: RedditOutlined,
        menuSeparator: true,
        children: [
            {
                key: 'developer-1',
                path: 'is-developer',
                title: '已认证用户',
                icon: ContactsOutlined,
                component: () => <IsDeveloper />
            },
            {
                key: 'developer-2',
                path: 'case',
                title: '精选案例',
                icon: InsertRowRightOutlined,
                component: () => <DeveloperCase />
            }
        ]
    }
];

const Router: React.FC = () => {
    return (
        <Suspense fallback={<Decoration9 style={{width: '150px', height: '150px', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)'}}>加载中...</Decoration9>}>
            <Switch>
                {
                    <Route path='/' exact>
                        <Redirect to='/monitoring'></Redirect>
                    </Route>
                }
                {routes.map(item => {
                    if (item.children) {
                        return (
                            <Route path={item.path} key={item.key}>
                                {item.children.map(item1 => (
                                    <Route key={item1.key} path={item.path + '/' + item1.path} exact render={item1.component}></Route>
                                ))}
                            </Route>
                        );
                    } else {
                        return <Route key={item.key} path={item.path} exact render={item.component}></Route>;
                    }
                })}
                {/* <Route path='/login'></Route> */}
                <Route path='*' render={() => <div>404</div>}></Route>
            </Switch>
        </Suspense>
    );
};
export default Router;
