/*
 * @Author: mxh
 * @Date: 2021-10-18 09:11:03
 * @LastEditTime: 2022-01-25 17:50:02
 * @LastEditors: mxh
 * @Description: 全局api配置管理
 * @status:
 */
import axios from 'axios';
import store from '@/store/index';
import React from 'react';
import storage from '@/utils/storage';
import qs from 'qs';
const {baseURL,AidLoginURL} = store.getState();

const service = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? '/api/v1' : baseURL + '/api/v1', //prod
    timeout: 5000
});

let tokenIsRefreshing=false;
let requestArr=[];

// 请求拦截
service.interceptors.request.use(
    function (config) {
        let user = storage.get('user','object');
        if (user?.access_token) {
            config.headers.Authorization = `Bearer ${user?.access_token}`; // 携带token
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// 响应拦截
service.interceptors.response.use(
    function (res) {
        let {code, message} = res.data;
        if (code < 211) {
            return res;
        } else {
            if (code === 4011) {
                //token过期，刷新token
                return refreshToken(res);
            }
            message && React.$message.error(message);
            if(code===301){
                window.location.href = `${AidLoginURL}?${qs.stringify({
                    redirect: window.location.origin + window.location.pathname + window.location.search
                })}`;
            }
            return Promise.reject(message);
        }
     
    },
    function (error) {
        if (error.response && error.response.status === 404) {
            React.$message.error('数据获取失败!');
        } else if (error.message.includes('timeout')) {
            React.$message.error('网络超时!');
        } else {
            const {message} = error.response;
            message && React.$message.error();
            return Promise.reject(error);
        }
    }
);

const refreshToken=async (response)=>{
    if(tokenIsRefreshing){
        return new Promise((resolve)=>{
            requestArr.push(()=>{
                resolve(service(response?.config));
            })
        })
    }else{
        tokenIsRefreshing=true;
        let user = storage.get('user','object');
        let res;
        try{
            res = await service('/token/refresh',{
                method:'POST',
                baseURL:'/aid_user_api',
                data:{
                    refresh_token:user?.refresh_token
                }
            })
            const {refresh_token,access_token}=res?.data?.data;
            storage.set('user',{...user,refresh_token,access_token});
        }catch(_){
            storage.clear();
            const {search,pathname}=window.location;
            window.location.href = `${AidLoginURL}?${qs.stringify({
                redirect: window.location.origin + pathname + search,
              })}`;
        }
        requestArr.forEach((fn)=>fn());
        requestArr=[];
        tokenIsRefreshing=false;
        return service(response?.config);
    }
}

export default service;

// post方法
export function postAction(url, parameter) {
    return service({
        url: url,
        method: 'post',
        data: parameter
    });
}

// get方法
export function getAction(url, parameter) {
    return service({
        url: url,
        method: 'get',
        params: parameter
    });
}

// delete方法
export function deleteAction(url, parameter) {
    return service({
        url: url,
        method: 'delete',
        params: parameter
    });
}

// put方法
export function putAction(url, parameter) {
    return service({
        url: url,
        method: 'put',
        data: parameter
    });
}

export function getHttpAction(url) {
    return axios.get({
        url: url,
        method: 'get'
    });
}
