const dark = flag => {
    if (flag) {
        // 白天主题
        return {
            headerBg: '#fff',
            mainBg: '#f4f5f5'
        };
    } else {
        // 黑夜主题
        return {
            headerBg: '#333',
            mainBg: '#666'
        };
    }
};
export default dark;
