/*
 * @Author: mxh
 * @Date: 2021-10-18 09:11:03
 * @LastEditTime: 2022-01-24 18:38:53
 * @LastEditors: mxh
 * @Description: 最外层组件app
 */

import React, {Component} from 'react';
import './app.less';
import {Layout} from 'antd';
import SideBar from './components/common/sideBar/sideBar';
import Router from './router/index';
import {Route, Switch, withRouter} from 'react-router-dom';
import Header from './components/common/header/index';
import store from '@/store/index';
import {api_getUserInfo, api_getReportList} from '@/api/user';
import qs from 'qs';
import storage from './utils/storage';
const {Content} = Layout;

const {AidLoginURL} = store.getState();

interface User {
    access_token: string;
    refresh_token: string;
    profile?: string;
    username: string;
}

// @ts-ignore
class App extends Component<any, any> {
    state = {
        loading: false,
        routePath: '',
        needLogin: false
    };
    UNSAFE_componentWillMount() {
        // 订阅loading变化
        store.subscribe(() => {
            this.setState({
                loading: store.getState().loading
            });
        });
        this.saveToken();
    }
    componentDidMount() {
        const script = document.createElement('script');
        script.src = 'https://turing.captcha.qcloud.com/TCaptcha.js';
        script.async = true;
        document.body.appendChild(script);

        const flag = this.props.history.location.pathname.includes('/content/report-info');
        const token = store.getState().token;
        if (!flag && token) {
            // 获取举报数量
            api_getReportList({
                index: 1,
                size: 10,
                pages: 1,
                total: 1
            }).then((res: any) => {
                const {code, data} = res.data;
                if (code === 200) {
                    store.dispatch({
                        type: 'setReportNum',
                        payload: data.list.filter(item => !item.status).length || 0
                    });
                }
            });
        }
    }
    componentDidUpdate(): void {}

    saveToken() {
        const user = storage.get('user', 'object') as User;
        const query = window.location.search.split('?').join('');
        const queryObj = qs.parse(query);
        const {access_token, refresh_token} = queryObj;
        delete queryObj.access_token;
        delete queryObj.refresh_token;
        if (access_token && refresh_token) {
            storage.set('user', {access_token, refresh_token, username: user?.username});
            const queryStr = qs.stringify(queryObj);
            if (queryStr) {
                window.location.href = window.location.pathname + '?' + qs.stringify(queryObj);
            } else {
                window.location.href = window.location.pathname;
            }
            return;
        }
        store.dispatch({
            type: 'setToken',
            payload: user?.access_token || access_token
        });
        if (!user?.access_token) {
            window.location.href = `${AidLoginURL}?${qs.stringify({
                redirect: window.location.origin + this.props.history.location.pathname + this.props.history.location.search
            })}`;
            return;
        }
        if (user?.access_token) {
            api_getUserInfo({}).then(res => {
                const {code, data, message} = res.data as resRule;
                if (code < 205) {
                    store.dispatch({
                        type: 'setUserInfo',
                        payload: {...data, profile: data?.img}
                    });
                    storage.set('user', {...user, username: data?.username, profile: data?.img});
                } else {
                    React.$message.error(message);
                }
            });
        }
    }
    render() {
        return (
            <Switch>
                {/* <Route path='/login' render={() => <Login />}></Route> */}
                {/* {this.state.needLogin && <Redirect to='/login'></Redirect>} */}
                <Route
                    path='*'
                    render={() => (
                        <div id='app'>
                            <Layout style={{minHeight: '100vh'}}>
                                <SideBar></SideBar>
                                <Layout id='page-right' className='site-layout'>
                                    <Header></Header>
                                    <Content style={{padding: '0 16px', background: React?.$dartData?.mainBg}}>
                                        {/* <Spin size='large' style={{ display: this.state.loading ? 'flex' : 'none' }} spinning={this.state.loading} tip='加载中请稍后...' /> */}
                                        <div className='site-layout-background content' style={{padding: 24, display: this.state.loading ? 'none' : 'block'}}>
                                            <Router></Router>
                                        </div>
                                    </Content>
                                </Layout>
                            </Layout>
                        </div>
                    )}></Route>
            </Switch>
        );
    }
}

export default withRouter(App);
