import {isNumber, isString} from 'lodash';

export type Maybe<T> = null | undefined | T;

function get<T>(key: string, type: 'string' | 'object' = 'string'): Maybe<T | string> {
    try {
        const state = localStorage.getItem(key);

        if (!state) return null;
        if (type === 'string') return state;
        return JSON.parse(state);
    } catch (e) {
        console.error(e);
        return null;
    }
}

function set<T>(key: string, value: T) {
    if (value === undefined || value === null) {
        localStorage.removeItem(key);
    } else if (isString(value) || isNumber(value)) {
        localStorage.setItem(key, value.toString());
    } else {
        localStorage.setItem(key, JSON.stringify(value));
    }
}
const clear = () => {
    localStorage.clear();
};

export default {
    set,
    get,
    clear
};
