/*
 * @Author: mxh
 * @Date: 2021-10-18 09:11:03
 * @LastEditTime: 2022-01-25 11:02:05
 * @LastEditors: mxh
 * @Description: redux
 * @status:
 */

const defaultState = {
    // baseURL: 'http://192.168.110.29:9527', // 测试请求地址
    // baseURL: 'http://192.168.110.201:9527', // 测试请求地址
    baseURL: 'https://manage.aidlux.com',
    AidLoginURL: process.env.NODE_ENV === 'development' ? 'http://192.168.110.201:26666' : 'https://auth.aidlux.com',
    // testURL: 'http://192.168.110.29:8090',
    // onlineURL: 'https://community.aidlux.com',
    userInfo: {
        profile:'',//用户头像
        nickname:'',//昵称
    }, // 记录初始用户信息
    isLogin: false, // 判断登录状态
    uploadUrl: 'https://community.aidlux.com/api/v1/file_upload/image', // 图片上传地址
    loading: false, // 全局加载状态
    routeLoction: '',
    downUrl: 'https://aidlux.oss-cn-beijing.aliyuncs.com/', // 图片下载地址
    token: '',
    theme: true, // 夜间模式 true => 明 , false => 暗
    reportNum: 0
};
export const reducer = (state: any = defaultState, action: any) => {
    switch (action.type) {
        case 'changeLoding':
            return {...state, loading: action.payload};
        case 'setToken':
            return {...state, token: action.payload};
        case 'setUserInfo':
            return {...state, userInfo: action.payload};
        case 'setTheme':
            return {...state, theme: action.payload};
        case 'setReportNum':
            return {...state, reportNum: action.payload};
        default:
            return state;
    }
};
