/*
 * @Author: mxh
 * @Date: 2021-11-30 15:53:58
 * @LastEditTime: 2022-01-24 18:40:40
 * @LastEditors: mxh
 * @Description: 全局状态管理--redux
 * @status:
 */
import {createStore} from 'redux';
import {reducer} from './reducer';
const store = createStore(reducer);

export default store;
