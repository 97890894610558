/*
 * @Author: mxh
 * @Date: 2021-11-30 15:53:58
 * @LastEditTime: 2022-01-24 17:27:48
 * @LastEditors: mxh
 * @Description: 公共头部组件
 * @status:
 */
import React, {FC} from 'react';
import {Layout, Row, Image, Menu, Dropdown, Switch} from 'antd';
import './index.scoped.less';
import {Decoration6} from '@jiaminghi/data-view-react';
import {withRouter} from 'react-router-dom';
import store from '@/store';
import storage from '@/utils/storage';
import qs from 'qs';
const {Header} = Layout;

const HeaderComponent: FC = (props: any) => {
    const {profile} = store.getState().userInfo;
    const {AidLoginURL} = store.getState();
    /**
     * @description: 退出登录事件
     * @param {*}
     * @return {*}
     */
    const exitLogin = () => {
        const {history} = props;

        const {search, pathname} = history.location;
        storage.clear();
        // Note: There may be security issues, please note

        window.location.href = `${AidLoginURL}?${qs.stringify({
            redirect: window.location.origin + pathname + search
        })}`;
    };
    // 头像下拉菜单
    const menu = (
        <Menu>
            <Menu.Item key='header1'>
                <div className={'loginOut'} onClick={exitLogin}>
                    退出登录
                </div>
            </Menu.Item>
        </Menu>
    );
    // 夜间模式按钮改变
    const darkModeChange = value => {
        store.dispatch({
            type: 'setTheme',
            payload: value
        });
    };
    // icon组件
    const Icon = str => <i className={'iconfont ' + str}></i>;

    return (
        <Header className='site-layout-background' style={{padding: 0, background: React?.$dartData?.headerBg}}>
            <Row id='header' align={'middle'}>
                <Switch className='theme' checkedChildren={Icon('icon-taiyang')} unCheckedChildren={Icon('icon-yueliang')} defaultChecked onChange={darkModeChange}></Switch>
                <Decoration6 style={{width: '300px', height: '30px'}} />
                <div className={'header_login'}>
                    <Dropdown overlay={menu} placement='bottomCenter' arrow>
                        <Image className='userHead' src={profile ? React?.getImgUrl(profile) : null} />
                    </Dropdown>
                </div>
            </Row>
        </Header>
    );
};

export default withRouter(HeaderComponent);
