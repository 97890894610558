/*
 * @Author: mxh
 * @Date: 2021-10-18 09:11:03
 * @LastEditTime: 2022-01-25 09:07:25
 * @LastEditors: mxh
 * @Description: 项目入口
 */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '@/iconfont/iconfont.css';
// 引入less文件 可配置主题
// import 'antd/dist/antd.less';
import './index.less';
// import reportWebVitals from './reportWebVitals';
import {ConfigProvider} from 'antd';
// 引入中文包
import zhCN from 'antd/lib/locale/zh_CN';

import {Provider} from 'react-redux';
import store from './store/index';
import './api/require';
import {BrowserRouter} from 'react-router-dom';
import '@/common/common';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ConfigProvider locale={zhCN}>
                <App />
            </ConfigProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
